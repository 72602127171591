<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok"></div>
    <div id='printMe' style=" width: 21cm; margin: 0 auto;">
      <!--page1-->
      <Cover v-if="dataover" :stuInfo="stuInfo"></Cover>
      <div v-if="dataover" class="A4Container"></div>


      <Report1 v-if='dataover1' :dataInfo='dataInfo'></Report1>
      <Report2 v-if='dataover1' :dataInfo='dataInfo'></Report2>
    </div>


  </div>
</template>

<script>
import Cover from './com_report/Cover'
import Report1 from './com_report/report_01'
import Report2 from './com_report/report_02'

export default {
  name: 'export',
  components: {
    Cover, Report1, Report2
  },
  data() {
    return {
      pdfok: false,
      dataover: true,
      dataover1: true,
      printObj: {
        id: 'printMe',
        popTitle: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      userid: '',
      cover: {},
      info: {},
      stuInfo: {},


      dataInfo: {},
      schoolName: '',
    }
  },
  mounted() {
    let id = this.getParams('id')
    let dataid = this.getParams('dataid')
    let pmid = this.getParams('pmid')
    this.getConfig(pmid)
    this.getDataList(id, dataid, pmid)
  },

  methods: {
    //过滤特殊字符, 校验所有输入域是否含有特殊符号
    escape2Html(str) {
      var arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"', 'middot': '·', '↵': ' \n ' }
      return str.replace(/&(lt|gt|nbsp|amp|quot|middot);/ig, function (all, t) {
        return arrEntities[t]
      })
    },
    getConfig(pmid) {
      this.$http.post("/api/sch_config_one", { ktype: "schoolname", pmid: pmid }).then(res => {
        this.schoolName = res.data.kvalue
      })
    },
    getDataList(id, dataid, pmid) {
      let _this = this
      this.$http.post('/api/export_stu_week_report_single', {
        stu_id: parseInt(id) ? parseInt(id) : id,
        report_id: dataid,
        pmid: parseInt(pmid)
      }).then(res => {
        if (res.data) {
          let rData = res.data
          if (res.data.stuInfo && res.data.stuInfo.jiguan) {
            let arr = this.findDeep(this.chinaCitys, res.data.stuInfo.jiguan)
            let arr1 = this._lo.reverse(arr)
            let str = this._lo.map(arr1, function (n) {
              return n.label
            }).join(',')
            res.data.stuInfo.jiguan = str
          }
          if (res.data.stuInfo && res.data.stuInfo.skill_img && this.isJSON(res.data.stuInfo.skill_img)) {
            res.data.stuInfo.skill_img = JSON.parse(res.data.stuInfo.skill_img)
          }
          if (res.data.stuInfo && res.data.stuInfo.skill_json && this.isJSON(res.data.stuInfo.skill_json)) {
            res.data.stuInfo.skill_json = JSON.parse(res.data.stuInfo.skill_json)
          }
          let stuInfo = rData.stuInfo ? rData.stuInfo : {}
          let bzrInfo = rData.bzrInfo ? rData.bzrInfo : {}
          let enterprise = rData.enterprise ? rData.enterprise : {}
          this.stuInfo = Object.assign({},
            { ...stuInfo },
            { ...bzrInfo },
            { ...enterprise },
            {
              xuerxiao: rData.xuerxiao,
              mentorName: rData.mentorName,
              nationName: rData.nationName,
              politicalStatusName: rData.politicalStatusName,
              professionalName: rData.professionalName,
              sexName: rData.sexName,
              startTime: rData.startTime,
              endTime: rData.endTime,
              createDate: rData.createDate,
            })


          if (rData.zhoubao && rData.zhoubao.length > 0 && rData.zhoubao[0] && rData.zhoubao[0].report_info) {
            let report_info = JSON.parse(rData.zhoubao[0].report_info)
            report_info.bzrComment = rData.zhoubao[0].review_comment || ''
            if (rData.zhoubao[0].sign_img) {
              rData.zhoubao[0].sign_img = rData.zhoubao[0].sign_img.split('?')[0] + '?x-oss-process=image/resize,w_200'
            }
            report_info.bzrSignUrl = rData.zhoubao[0].sign_img || ''
            if (report_info.formName && report_info.formName.indexOf('_') > -1) {
              let formNameArr = report_info.formName.split('_')
              if (formNameArr && formNameArr.length == 4) {
                report_info.weekdate = `${formNameArr[2]}年 第${formNameArr[3]}周`
              }
            }
            this.dataInfo = report_info
          }
          // console.log(115, this.dataInfo)
        }

        this.$nextTick(() => {
          setTimeout(() => { _this.pdfok = true }, 500)
        })

      })
    },

    getParams(name) {
      let n_url = window.location.href
      n_url = n_url.replace('/#', '')
      if (n_url.indexOf('?') > 0) {
        n_url = n_url.split('?')[1]
      }
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = n_url.match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = []
      while (arr.length) {
        _arr.push(arr.splice(0, num))
      }
      return _arr
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {}
      array.forEach(function (o) {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    }
  }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>